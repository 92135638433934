/**
 * Max value for currency fields supported by the server.
 */
export const MAX_CURRENCY = 999_999_999.99;

/**
 * Min value for currency fields supported by the server.
 */
export const MIN_CURRENCY = -MAX_CURRENCY;

/**
 * Max value for signed int fields supported by the server.
 */
export const MAX_INT = 2_147_483_647;

export const PASSWORD_MIN_LENGTH = 6;
